.boxone {
  background-color: antiquewhite !important;
  border-radius: 5px;
}

.boxtwo {
  background-color: #FB634061;
  border-radius: 5px;
}

.boxthree {
  background-color: #11cdef4f;
  border-radius: 5px;
}

.bg-color {
  background-color: #007FA3;
}

.custom-placeholder::placeholder {
  color: grey !important;
  /* Set your desired placeholder color */
  opacity: 1 !important;
  /* Ensures the color is applied */
}


.subscription-container {
  text-align: center;
  margin-top: 50px;
}

.open-modal-btn {
  padding: 6px 10px;
  background-color: #5E72E4;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.20);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000;
}

.modal-content {
  background-color: #EFEFF0;
  padding: 20px;
  border-radius: 10px;
  width: 450px;
  text-align: center;
  position: relative;
}




.modal-title {
  font-size: 20px;
  color: black;
}

.close-modal-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  color: black;
  border: none;
  font-size: 18px;
  cursor: pointer;
}

.text-dark {
  color: black !important;
}

.subscription-form {
  margin-top: 20px;
}

.subscription-input {
  display: inline-flex;
  align-items: center;
  margin-bottom: 20px;
}

.subscription-input input {
  width: 60px;
  text-align: center;
  margin-right: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
}

.save-btn {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.save-btn:hover {
  background-color: #45a049;
}

.subscription-options {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.subscription-options label {
  margin-bottom: 10px;
}

.subscription-options input[type="radio"] {
  margin-right: 10px;
}

.custom-label {
  color: white !important;
}



.full-width-background {
  /* background-color: #007b8f; */
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw !important;
  height: 100vh !important;
  margin: 0;
  background-image: url(/src/assets/img/BGnew.png);
  background-repeat: no-repeat;
  background-size: contain;
}

.centered-content {
  background-color: #ffffff;
  padding: 10px 30px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.55) !important;
  max-width: 400px;
  width: 115%;
}

.mainlogo {
  display: flex;
  justify-content: center;
}

.radio-input-wrapper {
  display: flex;
}

.label {
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 14px 16px;
  margin: 0px 5px;
  cursor: pointer;
  transition: .3s;
  /* width: 50%; */
  /* margin-left: 25%; */
}

.table td {
  border-top: 1px solid #e9ecef;
  /* width: 100%; */
  vertical-align: middle;
}

.table th {
  border-bottom: 1px solid #e9ecef;
}

.label-text {
  color: hsl(0, 0%, 60%);
  margin-left: 8px;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 900;
  transition: .3s;
}

.label:hover,
.label:focus-within,
.label:active {
  background: hsla(0, 0%, 80%, .14);
}

.radio-input {
  position: absolute;
  left: 0;
  top: 0;
  width: 1px;
  height: 1px;
  opacity: 0;
  z-index: -1;
}

.radio-design {
  width: 15px;
  height: 15px;
  border-radius: 100px;
  background: linear-gradient(to right bottom, hsl(154, 97%, 62%), hsl(225, 97%, 62%));
  position: relative;
}

.radio-design::before {
  content: '';
  display: inline-block;
  width: inherit;
  height: inherit;
  border-radius: inherit;
  background: hsl(0, 0%, 90%);
  transform: scale(1.1);
  transition: .3s;
}

.radio-input:checked+.radio-design::before {
  transform: scale(0);
}

.radio-input:checked~.label-text {
  color: hsl(0, 0%, 40%);
}

.custom-modal {
  margin-top: 10rem;
}

.planbold {
  font-weight: 700;
}

.btndisable {
  cursor: context-menu;
}

.btnremove {
  box-shadow: none !important;
  background-color: transparent !important;

}

.btnremove:active {
  background-color: transparent !important;
}


/* CSS for the options container */
.option-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

/* CSS for the modal overlay */
.overlay-styles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* CSS for the modal itself */
.modal-styles {
  background-color: #1a1a2e;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: white;
}

/* CSS for the modal buttons container */
.modal-button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

/* CSS for the cancel button */
.cancel-button-style {
  background-color: #ff4c4c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* CSS for the confirm button */
.confirm-button-style {
  background-color: #00ff00;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* CSS for the unchecked icon */
.unchecked-icon-style {
  color: white;
  padding: 5px;
  font-size: 12px;
}

/* CSS for the checked icon */
.checked-icon-style {
  color: white;
  padding: 5px;
  font-size: 12px;
}


/* CSS for the modal overlay */
.overlay-styles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* CSS for the modal */
.modal-styles {
  background-color: #1a1a2e;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* CSS for the option container */
.option-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

/* CSS for the close button */
.close-button-style {
  background-color: #ff4c4c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}


.option-style {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

/* Overlay background when the modal is open */
.overlay-styles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Modal container styling */
.modal-styles {
  background-color: #1a1a2e;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  color: white;
}

/* Modal button container */
.modal-button-container {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
}

/* Confirm button */
.confirm-button-style {
  background-color: #00ff00;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

/* Cancel button */
.cancel-button-style {
  background-color: #ff4c4c;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
}

.sidebarimage {
  width: 130px;
  height: 130px;
}

@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md>[class*="container"] {
    align-items: center !important;
  }
}

@media (max-width: 575px),
(max-height: 600px) {
  .formaddstudent {
    width: 100% !important;
  }

  .centered-content {
    /* width: 100%; */
    padding: 0px 20px;
  }

  .navbar-vertical.navbar-expand-md .navbar-brand-img {
    max-height: 0rem !important;
  }

  .profile {
    height: 100% !important;
  }

  .plan {
    order: 1 !important;
    margin-bottom: 15px;
  }

  .planon {
    order: 2 !important;
  }
}


/* <========sidebarimage=========> */
.navbar-brand-img {
  /* height: 150%; */
}

.navbar-vertical.navbar-expand-md .navbar-brand-img {
  max-height: 8.5rem !important;
}
@media (min-width: 768px) {
  .navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
      padding: 6px 0  !important;
  }
}
/* .navbar-vertical.navbar-expand-md .navbar-nav .nav-link {
  margin-top: 15px !important;
} */


/* <===============delete btn=================> */
.confirm-button {
  background-color: #FF4C4C;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cancel-button {
  background-color: #2DCE89;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* <================input===========> */
.addstudent.inputfluid {
  border-radius: 12px;
  padding: 12px 15px;
  box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  font-size: 16px;
  transition: all 0.3s ease;
}

.addstudent.inputfluid:focus {
  background-color: white;
  border-color: #28a745;
  box-shadow: 0 0 6px rgba(40, 167, 69, 0.6);
}

.is-invalid {
  border-color: #dc3545;
  background-color: #ffe6e6;
}

.addstudent.inputfluid:hover {
  border-color: #007bff;
}

.avatar img{
  height: 35px !important;
}

.title{
  font-weight: 600;
  color: #32325D;
  font-size: 17px;
}