/* Custom CSS for Subscription Plans */
.subscription-plans {
  background-color: #ffffff;
}

.duration-selector {
  display: flex;
  justify-content: center;
}

.plan-card {
  border-radius: 15px;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.plan-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
}

.featured-plan {
  border: 2px solid var(--bs-primary);
  transform: scale(1.05);
}

.plan-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}

.plan-icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.plan-title {
  flex-grow: 1;
}

.plan-pricing .price {
  color: var(--bs-primary);
  font-weight: 700;
  font-size: 2.5rem;
}

.plan-pricing .duration {
  color: var(--bs-gray-600);
  font-size: 0.9rem;
}

.plan-features {
  margin-bottom: 1rem;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}

.feature-icon {
  color: var(--bs-success);
  margin-right: 10px;
  width: 20px;
  height: 20px;
}

.plan-btn {
  border-radius: 10px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn-icon {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

/* Custom color for 'Both' plan button */
.btn-purple {
  background-color: #6f42c1;
  color: white;
}

.btn-purple:hover {
  background-color: #5a32a3;
  color: white;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .plan-card {
    margin-bottom: 1rem;
  }
}
